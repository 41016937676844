import './about.scss';
import React, { FC } from 'react';
import Heading from './heading';

const About: FC = () => {
  return (
    <section id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <Heading heading="ABOUT ME" />
            <div className="content">
              <p>Things I value: Moving quickly, but also taking time to plan, design and understand. Communication. Ownership. Passion. People-skills. Considering the systems capabilities first before writing code.</p>
              <p>Experience: I like to think that I have somewhat of a diverse skill-set. I have held a few unique positions which allowed me to get a broad variety of experience with many different technologies. Here are a few, but there are many not included. Sql and NoSQL (I love DynamoDB). React (Typescript). Blockchain (Ethereum/Polygon/NFTs). .NET (C#/Entity Framework Core). Laravel/Symphony. Node. Many CMSs.</p>
              <p>Design patterns: Event-Driven architecture (MassTransit, Amazon SQS, Azure Service Bus), MVC pattern, API design (GraphQL, RESTful), DB design (ERD, Access Pattern documentation, ORMs), micro-service architecture, and Domain Driven Design patterns (DDD).</p>
              <p>Testing: I believe in owning and writing unit tests 😊</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;