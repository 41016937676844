import React, { FC } from 'react';

const Treasury4: FC = () => {
  return (
    <div className="work-item container">
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <div className="project-title">
            <h3>Treasury4</h3>
            <h4>Cutting Edge Treasury data management software.</h4>
          </div>
          <p><strong>Role:</strong> Software Engineer - Full Stack</p>
        </div>
        <div className="col-xs-6">
          <p><strong>Technologies Used:</strong></p>
          <ul>
            <li>C#</li>
            <li>Entity Framework Core</li>
            <li>Sql Server</li>
            <li>Azure</li>
            <li>MassTransit/Azure Service Bus (Topics, Subscriptions, Queues)</li>
            <li>React/Typescript</li>
          </ul>
        </div>
      </div>
      <div className="row center-xs">
        <div className="col-xs-12 col-sm-10">
          <img src="/treasury4-screenshot.png" alt="Awesimo.io" className="featured-project-image" />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <p className="text-center"><strong>Link to the corporate site:</strong> <a href="https://treasury4.com/" target="_blank" rel="noreferrer">Treasury4.com</a></p>
        </div>
      </div>
    </div>
  );
}

export default Treasury4;