import React, { FC, useEffect, useState } from "react";
import './hero.scss';

const Hero: FC = () => {
  const jobWords = ['web developer.', 'software engineer.', 'blockchain developer.', 'front-end developer.', 'back-end developer.'];
  const funWords = ['roller skater.', 'pickleball\'er.', 'gamer.', 'gardener.', 'musician.'];
  const [count, setCount] = useState(0);
  const [currentWord, setCurrentWord] = useState('<span class="animated">'+jobWords[jobWords.length - 1]+'</span>');
  const [currentFunWord, setCurrentFunWord] = useState('<span class="animated">'+funWords[funWords.length - 1]+'</span>');

  const dateDiffInDays = (a: Date, b: Date) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  const experienceInDays = dateDiffInDays(new Date("2015-06-16"), new Date());
  const experienceInYears = experienceInDays / 365;
  const leftOverDays = Math.floor(364 * (experienceInYears % 1));

  const setNextWord = (count: number) => {
    if (count + 1 >= jobWords.length) {
      setCount(0);
    } else {
      setCount(prevCount => prevCount + 1);
    }

    setCurrentWord('<span class="animated">'+jobWords[count]+'</span>');
    setCurrentFunWord('<span class="animated">'+funWords[count]+'</span>');
  };

  useEffect(() => {
    const interval = setInterval(() => setNextWord(count), 3000);

    return () => clearInterval(interval);
  }, [count, setNextWord]);

  return (
    <section id="hero">
      <div className="container">
        <div className="row middle-xs">
          <div className="col-xs-12">
            <h2><span className="whats-up">👋 <strong>What's up?</strong></span><br />Welcome to my little hub on the web.<br /></h2>
            <p>I'm a software engineer with <strong>{Math.floor(experienceInYears)} years</strong> and <strong>{leftOverDays} days experience</strong> and counting!</p>
            <div className="divider centered"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;